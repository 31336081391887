/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.image-text {
  padding: 28px 0;
}
@media only screen and (min-width: 768px) {
  .image-text {
    padding: 44px 0;
  }
}
@media only screen and (min-width: 1400px) {
  .image-text {
    max-width: 1360px;
    margin: 0 auto;
  }
}
.image-text.background-transparent {
  background-color: transparent;
}
.image-text.background-white {
  background-color: white;
}
.image-text.background-grey {
  background-color: #ece9e8;
}
.image-text .block-wrapper {
  display: flex;
  padding: 0 16px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 901px) {
  .image-text .block-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 40px;
  }
}
@media only screen and (min-width: 768px) {
  .image-text .block-wrapper {
    align-items: center;
    column-gap: 56px;
    justify-content: center;
  }
  .image-text .block-wrapper.image-right {
    flex-direction: row-reverse;
  }
  .image-text .block-wrapper .image-wrapper,
  .image-text .block-wrapper .text-wrapper {
    max-width: 435px;
    width: 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .image-text .block-wrapper {
    column-gap: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .image-text .block-wrapper {
    flex-direction: column;
  }
  .image-text .block-wrapper.text-image .image-wrapper {
    order: 2;
    min-height: 400px;
  }
  .image-text .block-wrapper.text-image .image-text-btn--wrapper {
    display: flex;
    order: 3;
  }
  .image-text .block-wrapper.text-image .text-wrapper .image-text-btn--wrapper {
    display: none;
  }
}
.image-text .block-wrapper.text-image .image-text-btn--wrapper {
  display: none;
}
.image-text .block-wrapper.text-image .text-wrapper .image-text-btn--wrapper {
  display: block;
}
.image-text .image-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
.image-text picture {
  display: inline-block;
}
.image-text img {
  max-width: 100%;
}
.image-text .title {
  font-family: "HeadingProTreble-Heavy", sans-serif;
  font-size: 32px;
  line-height: 120%;
  margin: 0 0 24px;
  text-transform: uppercase;
}
.image-text .title .first-part {
  color: #fe7000;
}
.image-text .description {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
}
.image-text .main-text,
.image-text .readmore-wrapper {
  margin: 0 0 24px;
}
.image-text .main-text ul li,
.image-text .readmore-wrapper ul li {
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 12px;
  padding-left: 34px;
  position: relative;
}
@media only screen and (min-width: 901px) {
  .image-text .main-text ul li,
  .image-text .readmore-wrapper ul li {
    font-size: 20px;
    line-height: 24px;
  }
}
.image-text .main-text ul li::before,
.image-text .readmore-wrapper ul li::before {
  border-style: solid;
  border-width: 3px 3px 0 0;
  color: #fe7000;
  content: "";
  display: inline-block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 6px;
  transform: rotate(135deg);
  width: 18px;
}
.image-text .main-text ol,
.image-text .readmore-wrapper ol {
  list-style: normal;
  list-style-position: inside;
}
.image-text .readmore-wrapper.collapsed .readmore-text {
  display: none;
}
.image-text .readmore-wrapper.collapsed a::before {
  content: attr(data-read-more);
}
.image-text .readmore-wrapper .readmore-text {
  display: block;
}
.image-text .readmore-wrapper .readmore-button {
  display: inline-block;
  font-size: 16px;
  line-height: 130%;
  text-decoration: underline;
  text-transform: none;
}
.image-text .readmore-wrapper .readmore-button::before {
  content: attr(data-read-less);
}
@media only screen and (min-width: 901px) {
  .image-text .readmore-wrapper .readmore-button:hover {
    text-decoration: none;
  }
}
@media only screen and (max-width: 767px) {
  .image-text .image-text-btn--wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.image-text--lineplay {
  position: relative;
}
.image-text--lineplay::before {
  background-image: url("../../../../img/svg/lineplay-imagetext.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 814px;
  left: 0;
  position: absolute;
  top: -160px;
  max-width: 1600px;
  width: 100%;
  z-index: 0;
}
@media only screen and (min-width: 768px) {
  .image-text--lineplay::before {
    top: 0;
  }
}
@media only screen and (min-width: 1400px) {
  .image-text--lineplay::before {
    left: calc(50% - 794px);
  }
}
@media only screen and (min-width: 768px) {
  .image-text--lineplay.image-right::before {
    transform: scaleX(-1);
    left: auto;
    right: calc(50% - 794px);
  }
}
@media only screen and (max-width: 767px) {
  .image-text--lineplay.text-image::before {
    top: auto;
    bottom: -275px;
  }
}
@media only screen and (max-width: 767px) {
  .image-text--lineplay.image-text:has(.image-text)::before {
    top: -48px;
  }
}