.image-text {
    padding: 28px 0;

    @include mq($not-mobile) {
        padding: 44px 0;
    }

    @include mq($desktop-xxl) {
        max-width: 1360px;
        margin: 0 auto;
    }

    &.background-transparent {
        background-color: transparent;
    }

    &.background-white {
        background-color: white;
    }

    &.background-grey {
        background-color: $wild-sand;
    }

    .block-wrapper {
        display: flex;
        padding: 0 16px;
        position: relative;
        z-index: 1;

        @include mq($not-mobile-tablet-pt) {
            margin: 0 auto;
            max-width: 1200px;
            padding: 0 40px;
        }

        @include mq($not-mobile) {
            align-items: center;
            column-gap: 56px;
            justify-content: center;

            &.image-right {
                flex-direction: row-reverse;
            }

            .image-wrapper,
            .text-wrapper {
                max-width: 435px;
                width: 50%;
            }
        }

        @include mq($tablet-max) {
            column-gap: 32px;
        }

        @include mq($mobile-max) {
            flex-direction: column;

            &.text-image {
                .image-wrapper {
                    order: 2;
                    min-height: 400px;
                }

                .image-text-btn--wrapper {
                    display: flex;
                    order: 3;
                }

                .text-wrapper {
                    .image-text-btn--wrapper {
                        display: none;
                    }
                }
            }
        }

        &.text-image {
            .image-text-btn--wrapper {
                display: none;
            }

            .text-wrapper {
                .image-text-btn--wrapper {
                    display: block;
                }
            }
        }
    }

    .image-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    picture {
        display: inline-block;
    }

    img {
        max-width: 100%;
    }

    .title {
        font-family: $heading-pro-treble-heavy;
        font-size: 32px;
        line-height: 120%;
        margin: 0 0 24px;
        text-transform: uppercase;

        .first-part {
            color: $safety-orange;
        }
    }

    .description {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
    }

    .main-text,
    .readmore-wrapper {
        margin: 0 0 24px;

        ul {
            li {
                font-family: $heading-pro-treble-extra-bold;
                font-size: 16px;
                font-style: normal;
                line-height: 20px;
                margin-bottom: 12px;
                padding-left: 34px;
                position: relative;

                @include mq($not-mobile-tablet-pt) {
                    font-size: 20px;
                    line-height: 24px;
                }

                &::before {
                    border-style: solid;
                    border-width: 3px 3px 0 0;
                    color: $safety-orange;
                    content: "";
                    display: inline-block;
                    height: 10px;
                    left: 0;
                    position: absolute;
                    top: 6px;
                    transform: rotate(135deg);
                    width: 18px;
                }
            }
        }

        ol {
            list-style: normal;
            list-style-position: inside;
        }
    }

    .readmore-wrapper {
        &.collapsed {
            .readmore-text {
                display: none;
            }

            a {
                &::before {
                    content: attr(data-read-more);
                }
            }
        }

        .readmore-text {
            display: block;
        }

        .readmore-button {
            display: inline-block;
            font-size: 16px;
            line-height: 130%;
            text-decoration: underline;
            text-transform: none;

            &::before {
                content: attr(data-read-less);
            }

            &:hover {
                @include mq($not-mobile-tablet-pt) {
                    text-decoration: none;
                }
            }
        }
    }

    .image-text-btn--wrapper {
        @include mq($mobile-max) {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}

.image-text--lineplay {
    position: relative;

    &::before {
        background-image: url("../../../../img/svg/lineplay-imagetext.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        height: 814px;
        left: 0;
        position: absolute;
        top: -160px;
        max-width: 1600px;
        width: 100%;
        z-index: 0;

        @include mq($not-mobile) {
            top: 0;
        }

        @include mq($desktop-xxl) {
            left: calc(50% - 794px);
        }
    }

    &.image-right {
        @include mq($not-mobile) {
            &::before {
                transform: scaleX(-1);
                left: auto;
                right: calc(50% - 794px);
            }
        }
    }

    &.text-image::before {
        @include mq($mobile-max) {
            top: auto;
            bottom: -275px;
        }
    }

    &.image-text:has(.image-text)::before {
        @include mq($mobile-max) {
            top: -48px;
        }
    }
}
